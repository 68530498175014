export default {
  tegut: 'tegut-eixae6',
  tegutDemo: 'tegut-demo-n94qdzli',
  leinweber: 'leinweber-baucentrum-5cvbt954',
  wanzl: 'wanzl-mzio7s',
  snabbleSupermarket: 'snabble-supermarket-71u283',
  demo: 'demo',
  gooods: 'migrolino-9ef12e',
  teegschwendnerNuernberg: 'teegschwendner-nuernberg-ookee7ie',
  teegschwendnerCompany: 'tee-geschwendner-jo3ahy6i',
  teegschwendnerDemo: 'teegs-demo-yv961aof',
  teegschwendnerMuelheim: 'teegschwendner-muelheim-pnudd6y0',
  teegschwendnerBadenBaden: 'teegschwendner-bb-54d3n5e',
  teegschwendnerHamburg2: 'teegschwendner-hamburg-2-naams8sl',
  teegschwendnerBremen: 'teegschwendner-bremen-kl8rsrzm',
  teegschwendnerHannover: 'teegschwendner-hannover-47ahuqe4',
  teegschwendnerKoeln: 'teegschwendner-koeln-y1vqi5ws',
  teegschwendnerHamburg: 'teegschwendner-hamburg-fmsagkwv',
  teegschwendnerFrankfurt: 'teegschwendner-frankfurt-o6lqj62r',
  teegschwendnerHuerth: 'teegschwendner-huerth-qmao3dxh',
  teegschwendnerBonn: 'teegschwendnerbonn-geb2epvw',
  teegschwendnerGodesberg: 'teegschwendner-bad-godesb-lo5a1339',
  teegschwendnerTrier: 'teegschwendner-trier-lh1rbatm',
  panOston: 'pan-oston-6093v2',
  pyramid: 'pyramid-3429g8',
  tobaccoland: 'tobaccoland-4257p2',
  maymetzger: 'maymetzger-747ef4',
  tchibo: 'tchibo-3583dee',
  tchiboDemo: 'tchibo-demo-2ro8hu',
  test: 'test-ieme8a',
  integrationTest: 'test-project-checkout-ns-f112cc40',
  astorGamble: 'astor-demo-8h19c0nu',
  peekCloppenburgTestlab: 'pc-testlab-guhuus7b',
  hellweg: 'hellweg-zi6ayu',
  hornbach: 'hornbach-de-orqqd1ag',
};
